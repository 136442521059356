<template>
  <div>
      <b-navbar>
          <b-navbar-brand>SYSTEM SETTINGS</b-navbar-brand>
      </b-navbar>
      <b-nav pills class="ml-2 mb-2">
      <!-- <b-nav-item v-show="false" :active="$route.name=='general'" :link-classes="{'text-dark':!($route.name==='general')}" to="/settings">General</b-nav-item> -->
      <b-nav-item :active="$route.name=='farmi'" :link-classes="{'text-dark':!($route.name==='farmi')}" to="/settings/farm_input">Farm Inputs</b-nav-item>
      <b-nav-item :active="$route.name=='sproduce'" :link-classes="{'text-dark':!($route.name==='sproduce')}" to="/settings/produce">Produce</b-nav-item>
      <b-nav-item v-if="false" :active="$route.path.includes('buyers')" :link-classes="{'text-dark':!($route.name==='buyers')}" to="/settings/buyers">Buyers</b-nav-item>
      <b-nav-item :active="$route.name=='sysusers'" :link-classes="{'text-dark':!($route.name==='sysusers')}" to="/settings/sysusers">System Users</b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
